@import '../variables';

.main-wrapper{
    .prize-details__text-section{
        align-items: center;
        margin-bottom: 2em;
        
        div {margin-top: 1.5rem;}
        h2, h4 {
            font-style: normal;
            line-height: 1em;

            @media screen and (max-width:600px) {
            }
        }
        h2 {
            text-transform: uppercase;
            font-family: $heading-font;
        }
        h4 {font-weight: bold;}

        p{
            margin-top: 1em;
            @media screen and (max-width:600px) {
            }
        }
        &.grand-prize .col-md-6 {
            div {margin: 1.5rem 0;}
            img {
                margin-top: 3rem;
                @media screen and (max-width: 768px) {
                    margin-top: .5rem;
                }
            }
        }
        &:not(.grand-prize) {
           
            @media screen and (max-width: 767.9px) {
                flex-direction: column-reverse;
            }
            .img {
                overflow: hidden;
                img {
                    margin-left: -50px;
                    margin-right: -50px;
                    width: calc(100% + 100px);
                    max-width: unset;
                    @media screen and (max-width: 768px) {
                        &:last-of-type {
                          margin-bottom: -30px;  
                        }
                    }
                }
            }
        }

        .img{
            @media screen and (max-width: 767.9px) {
                margin-bottom: 2em;
            }

            img{
                max-width: 100%;
                &.overflow {
                    margin-left: -2em;
                    margin-right: -2em;
                    max-width: calc(100% + 4em);
                }
               
            }
            @media screen and (min-width:600px) {
                &.left-overlap div {
                    margin-left: -40px;
                }
                &.right-overlap div {
                    margin-right: -40px;
                }
            }
        }

        ul{
            list-style-type: disc;
            margin-left: 20px;

            li{
                margin: 10px 0;
            }
        }
       h4, p {
            margin: .25rem 0;
        }
       
    }
    .packshots { max-width: 75%; }
    .product-list {
        p { column-count: 2; }
        margin-bottom: 2em;
        margin-top: 2em;
    }
}

