@import '../variables';

//PIN form

.pin-form__container, .info-form__container, .stq-form-container {
    max-width: 375px;

    @media screen and (min-width: 768px) {
        position: relative;
    }
}
.pin-form__container{

    .form__heading{
        color: white;
        text-transform: none;
        font-family: $heading-font;
    }

    img {
        width: 100%;
        height: 6em;
        object-fit: cover;
        object-position: bottom;
        margin-top: 1em;
    }

    .form__description{
        color: white;
        margin: 20px auto;
        font-size: 1.2em;
        
        a { text-decoration: underline; color: $white; }
        small { display: block; }
    }

    .pin-form {
        max-width: 300px;
        margin: 0 auto;
        padding-bottom: 100px;
        position: relative;

        form{
            position: relative;

            .pinFormPopupButton{
                position: absolute;    
                right: 10px;
                top: 10px;
                cursor: pointer;

                img{
                    display: flex;
                    max-width: 2em;
                }
            }
        }

        .MuiInputBase-root, .MuiFormLabel-root {
            font-family: $body-font;
        }

        .pinForm__errorMessage{
            position: relative;
            background: #dd0000;
            color: white;
            font-family: $body-font;
            line-height: 1.4;
            text-align: left;
            padding: 10px;
            margin-bottom: 25px;

            &:empty{
                display: none;
            }
        }

        //popup for pin form
        .pinFormPopup{
            position: absolute;
            z-index: 99999;
            max-width: 320px;
            background: white;
            border-radius: 8px;
            color: $black;
            border: 1px solid $cyan;
            left: 50%;
            top: -100px;
            transform: translateX(-50%);
            box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.21);

            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            a { text-decoration: underline; color: $black; }

            &.visible{
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            .pinFormPopup__closeButton {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;

                img{
                    max-width: 100%;
                }
            }
            
            .pinFormPopup--container{
                padding: 35px;
                padding-bottom: 15px;
                position: relative;
                .pinFormPupup__content {
                    text-align: left;

                    img {
                        max-width: 248px;
                        margin: 1em auto;
                    }
                }
            }
        }

        .MuiTextField-root{
            max-width: 355px;
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: none;

                &.Mui-focused{

                }

                .MuiFilledInput-input{
                    padding: 27px 12px 10px 0;
                    text-indent: 12px;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }
    }
}

//error message
.pin-not-found {
    position: fixed;
    bottom: 64px;
    padding: 30px;
    background: #dd0000;
    border-radius: 6px;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    left: 50%;
    transform: translate(-50%, 30px);
    display: flex;

    @media screen and (max-width:600px) {
        bottom: 40px;
        width: calc(100% - 20px);
    }

    button{
        background: $white;
        border-radius: 30px;
        color: #ff1606;
        margin-left: 20px;
        border: none;
    }
}

//info form
.main-wrapper .info-form__container {
    h1 {margin-top: 0px;}
}
.info-form__container{
    max-width: 375px;
    padding-bottom: 100px;

    .form__heading{
        color: white;
        text-transform: uppercase;
        font-family: $heading-font;
    }

    .form__description{
        color: white;
        font-size: 1.2em;
        margin: 20px auto 40px auto;
    }

    .MuiInputBase-root, .MuiFormLabel-root {
        font-family: $body-font;
    }

    .info-form {
        max-width: 650px;
        margin: 0 auto;

        .MuiTextField-root{
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00 !important;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);

                    + div.MuiInputBase-root{
                        // border: 2px solid $yellow;
                    }

                    ~ .MuiFormHelperText-root {
                        //display: none;
                    }
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: none;

                &.Mui-focused{
                    // border: 2px solid $yellow;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }

        .checkbox-input {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            margin: 1rem 0;
            gap: .75rem;
            .MuiIconButton-label {
                span {
                    position: absolute;
                    top: 0;
                }
            }
            .PrivateSwitchBase-root-1 {padding: 0px;}
            .checkbox-input__label{
                // font-family: sans-serif;
                font-size: .9em;
                color: $white;
                line-height: 1;

                a{
                    color: inherit;
                    text-decoration: underline;
                }
            }

            + .infoForm__errorMessage{
                position: relative;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                line-height: 1.4;
                text-align: left;
                padding-left: 30px;
                margin: 3px 0;

                &:empty{
                    display: none;
                }

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }
        }

        .MuiFormControlLabel-root{
            margin-right: 0;

            //for checkbox focus 
            > span:not(.MuiTypography-body1){
                min-height: 42px;
                min-width: 42px;

                span:focus-within{
                    outline: 2px solid;
                }
            }

            .MuiFormControlLabel-label{
                text-align: left;
                color:$white;
                line-height: 1.3;
                font-family: $body-font;
            }

            + .infoForm__errorMessage{
                position: relative;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                line-height: 2;
                text-align: left;
                padding-left: 30px;

                &:empty{
                    display: none;
                }

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }
        }

        .info-form__legal{
            text-align: left;
            color: $white;
            line-height: 1;

            margin-bottom: 40px;
        }

    }
}