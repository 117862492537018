@import '../../styles/variables';

.main-header {
    background: $blue-purple;
    position: fixed;
    width: 100%;
    top:0;
    z-index: 9998;
    height: 64px;
    display: flex;
    align-items: center;

    .container{
        .row{

            @media screen and (max-width: 768px) {
                flex-wrap: nowrap;
            }

            *{
                display: flex;
                align-items: center;
            }

            .lang{
                justify-content: flex-end;
                color: white;
                button {
                    font-family: $heading-font;
                    font-weight: 900;
                    border: none;
                    background: none;
                    color: $white;
                    transition: font-size .3s;
                    &.selected {
                        font-size: 1.2em;
                        pointer-events: none;
                    }
                }
            }

            .logo{
                align-items:center;
                justify-content: center;
                margin-top: 95px;
                text-align: center;
                img {
                    width: 100px;
                    position: fixed;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: -3rem;
                    // @media screen and (min-width: 1024px) {
                    //     margin-top: -6rem;
                    // }
                }
                > span {
                    display: block;
                    flex: 1;
                    span {
                        display: inline-block;
                        margin-top: -4rem;
                        @media all and (min-width: 768px) {
                            margin-top: -6rem;
                        }
        
                    }
                }
                
                
                @media screen and (max-width: 767.9px) {
                    position: absolute;
                    top: 76px;
                    margin-top: 0;
                    width: 100%;
                }
                @media screen and (min-width: 576px) and (max-width: 767.9px) {
                    width: 540px;
                    
                }
            }

            .menu{

                button{
                    background: transparent;
                    border: none;
                    color: white;
                    outline: none;

                    @media screen and (max-width: 460px) {
                        max-width: 70px;
                    }

                    img{
                        max-width: 54px;
                        height: auto;
                    }
                }
            }
        }
    }
}

.nav-wrapper {
    position: fixed;
    background: $navopen;
    fill-opacity:70%;
    backdrop-filter: blur(14px);
    width: 100%;
    height: 100%;
    transition: all .3s ease;
    z-index: 9999;
    max-width: 100%;
    top: 0;

    .container {
        height: 64px; // match header
        
        button {
            border: none;
            background: none;
            line-height: 64px;
            img {
                max-width: 44px;
                height: auto;
            }
        }
        
    }

    &.nav-closed{
        transform: translate3d(0, -100%, 0);
        opacity: 0;
        .nav-container { display: none; }
    }

    &.nav-opened{
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }

    .nav-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: calc(100% - 64px);

        @media screen and (max-height: 640px) {
            align-items: flex-start;
        }

        ul.nav-list{
            
            @media screen and (max-height: 640px) {
                margin-top: 30px;
            }

            .nav-list__item{
                list-style: none;
                text-align: center;
                margin-bottom: 2em;
        
                a{
                    font-family: $heading-font;
                    font-size: 1.5em;
                    font-weight: 700;
                    color: white;
                    text-align: center;
                    transition: transform .3s;
                    display: inline-block;

                    @media screen and (max-width:600px) {
                        max-width: 56%;
                    }
        
                    &:hover{
                        text-decoration: none;
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

@media all and (max-width: 768px) {
    .nav-wrapper.nav-opened {
        height: 100vh;
        width: 100vw;
    }
    .nav-wrapper .nav-container ul.nav-list .nav-list__item,
    .nav-wrapper .nav-container ul.nav-list .nav-list__item a {text-align: left;}
}