@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: $white;
    line-height: 1.2;

    h1, h2, h3, h4, h5, h6 {
        font-family: $heading-font;
        font-weight: 700;
        line-height: 1;
        margin: .5em auto;
        font-size: 1em;
        text-transform: uppercase;
    }
    h1 {
        font-size: 2.5em;
    }
    .text-xxl {
        font-size: 2.75em;
        line-height: .75;
        @media screen and (max-width: 768px) {
            font-size: 2.5em;
        }
    }
    h2 {
        font-size: 1.6em;
    }
    h2 span, h2 span span {display: block;}
    h3 { font-size: 1.4em; }

    .page-heading{
        h1{
            color: $white;
            text-transform: uppercase;
            margin-bottom: 2em;
            @media screen and (max-width: 768px) {
                margin-bottom: 1em;
            }
        }
    }

    .page-body{
        &.rules {
            text-align: left;
            list-style-type: none;
            counter-reset: css-counter 0;

            h4 { counter-increment: css-counter 1; }
            h4:before { content: counter(css-counter) ". "; }
        }
        .text-block{
            padding-bottom: 1em;

            p { font-weight: 300; }
            p.split-col { column-count: 2; }

            table { width: 100%; }
            table, th, td { border: 1px solid #fff; }
            td { padding: .5em; }

            h4, p, ul, table {
                padding: 0 0 .5em;
            }
            ul, ol {
                padding-left: 1em;
            }
            ul li { list-style: disc;}
            ol li { list-style:upper-alpha; }

            .page-block__body, > p {
                ol{
                    list-style: lower-alpha;
                    margin-left: 1em;
        
                    li{
                        margin: 10px 0;
                        padding-left: 5px;
                    }

                    &.numbered {
                        list-style: decimal;
                    }
                }

                ul{
                    list-style: disc;
                    margin-left: 1em;
    
                    li{
                        margin: 10px 0;
                    }
                }

                strong{
                    font-weight: bold;
                }

                a{
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    hyphens: none;
                }
            }
        }
    }
}